import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const affilatesApi = createApi({
  reducerPath: "affilatesApi",
  baseQuery,
  tagTypes: ["affilates", "single"],
  endpoints: (builder) => ({
    getAffilates: builder.query({
      query: ({ page, search }) => ({
        url: "affiliates",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["affilates"],
    }),
    getSingleAffilat: builder.query({
      query: (id) => ({
        url: `affiliates/show/${id}`,
        method: "get",
      }),
      providesTags: ["single"],
    }),
    getAvailableCoupons: builder.query({
      query: () => ({
        url: `affiliates/available-coupons`,
        method: "get",
      }),
      providesTags: ["single"],
    }),
    blockuUer: builder.mutation<any, any>({
      query: (id) => ({
        url: `affiliates/block-unblock/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["affilates"],
    }),
    assignCouponToUser: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `affiliates/assign-coupon/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["single"],
    }),
    // getSingleSong: builder.query({
    //   query: (id) => ({
    //     url: `songs/show/${id}`,
    //     method: "get",
    //   }),
    //   providesTags: ["songs"],
    // }),
    // updateSong: builder.mutation<any, any>({
    //   query: ({ body, id }) => ({
    //     url: `songs/update/${id}`,
    //     method: "PUT",
    //     body,
    //   }),
    //   invalidatesTags: ["songs"],
    // }),
    // deleteSong: builder.mutation<any, any>({
    //   query: (id) => ({
    //     url: `songs/delete/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["songs"],
    // }),
  }),
});

export const {
  useGetAffilatesQuery,
  useLazyGetAffilatesQuery,
  useBlockuUerMutation,
  useGetSingleAffilatQuery,
  useGetAvailableCouponsQuery,
  useAssignCouponToUserMutation,
} = affilatesApi;
