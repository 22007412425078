import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// Dropdown component
const Dropdown: React.FC<{ route: any , open:any , userRole:any}> = ({ route , open , userRole}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div style={{order: route.rank}}>
        <ListItemButton
            sx={[
                {
                minHeight: 48,
                px: 2.5,
                },
                open
                ? {
                    justifyContent: "initial",
                    }
                : {
                    justifyContent: "center",
                    },
            ]}
          onClick={() => setIsOpen(!isOpen)}
        >
        <ListItemIcon
            
            sx={[
            {
                minWidth: 0,
                justifyContent: "center",
            },
            open
                ? {
                    mr: 3,
                }
                : {
                    mr: "auto",
                },
            ]}
        >
            {route.icon}
        </ListItemIcon>
        <ListItemText
            primary={route.name}
            sx={[
            open
                ? {
                    opacity: 1,
                }
                : {
                    opacity: 0,
                },
            ]}
        />
          <span>{isOpen ? "▲" : "▼"}</span>
        </ListItemButton>
        {isOpen && route.children && (
          <ul style={{ listStyleType: "none", paddingLeft: "10px", marginTop: "5px" }}>
            {route.children.map((item : any) => (
              <li>
                <NavLink to={item.path ? `${item.path}` : "#"}>
                <ListItem
                    key={item.name}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <ListItemButton
                    sx={[
                        {
                        minHeight: 48,
                        px: 2.5,
                        },
                        open
                        ? {
                            justifyContent: "initial",
                            }
                        : {
                            justifyContent: "center",
                            },
                    ]}
                    >
                    <ListItemIcon
                        sx={[
                        {
                            minWidth: 0,
                            justifyContent: "center",
                        },
                        open
                            ? {
                                mr: 3,
                            }
                            : {
                                mr: "auto",
                            },
                        ]}
                    >
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={item.name}
                        sx={[
                        open
                            ? {
                                opacity: 1,
                            }
                            : {
                                opacity: 0,
                            },
                        ]}
                    />
                    </ListItemButton>
                </ListItem>
                </NavLink>
            </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  export default Dropdown;