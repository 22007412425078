import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

// Component for non-dropdown items
const SingleItem: React.FC<{ route: any , open: any , userRole:any}> = ({ route , open , userRole }) => {
    
    
    return (
    <li>
        <NavLink to={route.path ? `${route.path}` : "#"}>
        <ListItem
            key={route.name}
            disablePadding
            sx={{ display: "block" }}
        >
            <ListItemButton
            sx={[
                {
                minHeight: 48,
                px: 2.5,
                },
                open
                ? {
                    justifyContent: "initial",
                    }
                : {
                    justifyContent: "center",
                    },
            ]}
            >
            <ListItemIcon
                sx={[
                {
                    minWidth: 0,
                    justifyContent: "center",
                },
                open
                    ? {
                        mr: 3,
                    }
                    : {
                        mr: "auto",
                    },
                ]}
            >
                {route.icon}
            </ListItemIcon>
            <ListItemText
                primary={route.name}
                sx={[
                open
                    ? {
                        opacity: 1,
                    }
                    : {
                        opacity: 0,
                    },
                ]}
            />
            </ListItemButton>
        </ListItem>
        </NavLink>
    </li>
  )
}

  export default SingleItem;
