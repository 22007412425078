import React from "react";
import { Bigsection } from "../homePage/components/nav/style";
import { NavLink, Outlet } from "react-router-dom";

const Coupons = () => {
  return (
    <div>
      <Bigsection>
        <nav className="nav">
          <ul>
            <NavLink to="show">
              <li>All Coupons</li>
            </NavLink>
            <NavLink to="create">
              <li>Create Coupon</li>
            </NavLink>
          </ul>
        </nav>
        <div style={{ margin: "2rem 0" }}>
          <Outlet />
        </div>
      </Bigsection>
    </div>
  );
};

export default Coupons;
