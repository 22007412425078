import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const withDrawAffilatesApi = createApi({
  reducerPath: "withDrawAffilatesApi",
  baseQuery,
  tagTypes: ["withDrawAffilates", "single"],
  endpoints: (builder) => ({
    getWithDrawAffilates: builder.query({
      query: ({ page, search }) => ({
        url: "withdraw-affiliate-requests",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["withDrawAffilates"],
    }),
    getSingleWithDrawAffilat: builder.query({
      query: (id) => ({
        url: `withdraw-affiliate-requests/show/${id}`,
        method: "get",
      }),
      providesTags: ["single"],
    }),

    changeStatus: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `withdraw-affiliate-requests/change-status/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["single", "withDrawAffilates"],
    }),
    // getSingleSong: builder.query({
    //   query: (id) => ({
    //     url: `songs/show/${id}`,
    //     method: "get",
    //   }),
    //   providesTags: ["songs"],
    // }),
    // updateSong: builder.mutation<any, any>({
    //   query: ({ body, id }) => ({
    //     url: `songs/update/${id}`,
    //     method: "PUT",
    //     body,
    //   }),
    //   invalidatesTags: ["songs"],
    // }),
    // deleteSong: builder.mutation<any, any>({
    //   query: (id) => ({
    //     url: `songs/delete/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["songs"],
    // }),
  }),
});

export const {
  useGetWithDrawAffilatesQuery,
  useLazyGetWithDrawAffilatesQuery,
  useGetSingleWithDrawAffilatQuery,
  useChangeStatusMutation,
} = withDrawAffilatesApi;
