import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const promotionApi = createApi({
  reducerPath: "promotionApi",
  baseQuery,
  tagTypes: ["Promotion"],
  endpoints: (builder) => ({
    getPromotions: builder.query({
      query: ({ page, search }) => ({
        url: "promotional-affiliate",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["Promotion"],
    }),
    storePromotion: builder.mutation<any, any>({
      query: (body) => ({
        url: `promotional-affiliate/store`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Promotion"],
    }),
    getSinglePromotion: builder.query({
      query: (id) => ({
        url: `promotional-affiliate/show/${id}`,
        method: "get",
      }),
      providesTags: ["Promotion"],
    }),
    updatePromotion: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `promotional-affiliate/update/${id}`,
        method: "store",
        body,
      }),
      invalidatesTags: ["Promotion"],
    }),
    deletePromotion: builder.mutation<any, any>({
      query: (id) => ({
        url: `/promotional-affiliate/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Promotion"],
    }),
  }),
});

export const {
  useGetPromotionsQuery,
  useLazyGetPromotionsQuery,
  useStorePromotionMutation,
  useGetSinglePromotionQuery,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
} = promotionApi;
