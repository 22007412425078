import { ReactNode } from "react";
import { Navigate } from "react-router-dom";


interface RoleProtectedRouteProps {
    children: ReactNode;
    allowedRoles: string[];
  }

  const RoleProtectedRoute = ({ children, allowedRoles }: RoleProtectedRouteProps) => {
    const data: any = localStorage.getItem("octar-dash");
    let roles = JSON.parse(data)?.user?.roles;
    const userRole = roles[0]?.name;
    
  if (!data || !allowedRoles.includes(userRole)) {
    return <Navigate to="/" />; 
  }

  return <>{children}</>;
};

export default RoleProtectedRoute;
