import styled from "styled-components";

export const Bigsection = styled.nav`
  .nav {
    all: unset;
    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      list-style: none;
      a {
        width: 100%;
      }
      li {
        border: 1px solid black;
        width: 100%;
        text-align: center;
      }
    }
  }
`;
