import styled from "styled-components";

export const Bigsection = styled.section`
  overflow: auto;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    a {
      color: blue;
    }
    th,
    td {
      padding: 8px;
      padding: 8px;
      text-align: center;

      img {
        object-fit: contain;
        width: 150px;
        height: 140px;
        max-height: 100px; /* Ensure that images are not too tall */
      }
    }
    th,
    td {
      border: 1px solid black; /* Outline each cell */
      text-align: center; /* Center the text horizontally */
      vertical-align: middle; /* Center content vertically */
      padding: 10px; /* Add padding for better spacing */
    }
    .actions {
      display: flex;
      height: 100%;
      gap: 0.5rem;
      align-items: stretch;
      justify-content: center;
      width: 100%;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .content {
      max-width: 200px; /* Set the maximum width as needed */
      word-wrap: break-word; /* Allow words to break and wrap */
      white-space: normal; /* Allow content to wrap within the cell */
      max-height: 300px;
      overflow: auto;
    }
  }
  .create {
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: linear-gradient(
      135deg,
      #4e5ae2 0%,
      #9e32a4 54.17%,
      #d552d8 100%
    );
    border: none;
    padding: 17px 16px;
    border-radius: 12px;
    color: #ffffff;
  }
  .filterContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 18rem;
    position: relative;
    flex-wrap: wrap;
    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 50%;
      width: 11rem;
      max-width: 11rem;
      height: 2px;
      background-color: #9e32a4;
      transform: rotate(90deg);
    }
    .btns {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-top: 1rem;
      flex-wrap: wrap;

      div {
        flex: 1;
        button {
          width: 100%;
        }
      }
    }
  }
  .firstsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  .filter {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }
  .paginate {
    all: unset;
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0; */
  }
  .popper {
    .btns {
      display: flex;
    }
  }
  /* @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  } */
`;
