"use client";
import React, { useEffect } from "react";
import styled from "styled-components";

const Error = (error: any) => {
  console.log("Errrrrrrrrrrrrrrrr", error?.error?.data);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderErrors = () => {
    if (error?.error?.status == 422) {
      return Object?.keys(error?.error?.data?.errors || {})?.map((key) => {
        return (error?.error?.data?.errors[key] || [])?.map(
          (err: any, index: number) => (
            <p
              className="text-red-500"
              key={`${key}-${index}`}
            >{`${key?.replaceAll("_", " ")}: ${err}`}</p>
          )
        );
      });
    }
    if (error?.error?.status == 400) {
      return Object?.keys(error?.error?.data?.message || {})?.map((key) => {
        return (error?.error?.data?.message[key] || [])?.map(
          (err: any, index: number) => (
            <p
              className="text-red-500"
              key={`${key}-${index}`}
            >{`${key.replaceAll("_", " ")}: ${err}`}</p>
          )
        );
      });
    }
    if (error?.error?.status == 503) {
      return (
        <p className="text-red-500">
          The mail server could not deliver mail, account or domain may not
          exist, they may be blacklisted, or missing the proper dns entries.
        </p>
      );
    }
    if (error?.error?.status == 500) {
      return <p className="text-red-500">Server Error</p>;
    }
    return;
  };
  return (
    <Bigscetion className="flex flex-col justify-center items-center py-3 bg-white rounded-lg shadow-md mt-5">
      <h1 className="text-paragraph1-regular ">Erros</h1>
      {renderErrors()}
    </Bigscetion>
  );
};

export default Error;
const Bigscetion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
  box-shadow: 10px 10px 8px -7px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  .text-paragraph1-regular,
  .text-red-500 {
    color: red;
  }
`;
