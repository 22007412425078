import React, { Suspense, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dropdown from "./components/Dropdown";
import SingleItem from "./components/SingleItems";


import { IoSettings } from "react-icons/io5";
import {
  MyBsPerson,
  MyCalendarTodayOutlinedIcon,
  MyChevronRightIcon,
  MyFiBriefcase,
  MyFiClipboard,
  MyInboxIcon,
  MyIoDocumentTextOutline,
  MyMailIcon,
  MyMenuIcon,
} from "../common/icons";
import IconButton from "@mui/material/IconButton";
import { NavBarStyle } from "./NabVarStyle";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GiExitDoor, GiLoveSong } from "react-icons/gi";
import { ExitToApp } from "@mui/icons-material";
import { logOut } from "../../store/reducers/auth/authSlice";
import { IoHomeOutline } from "react-icons/io5";
import {
  FaProductHunt,
  FaQuestion,
  FaRegBell,
  FaTheRedYeti,
  FaUsers,
  FaWallet,
} from "react-icons/fa";
import { TbCategory } from "react-icons/tb";
import {
  MdOutlineFormatColorFill,
  MdOutlineNote,
  MdPayment,
} from "react-icons/md";
import { MdUnsubscribe } from "react-icons/md";
import { LiaBlogSolid } from "react-icons/lia";
import { RiCoupon3Line } from "react-icons/ri";
import { HiBuildingStorefront } from "react-icons/hi2";
import { FaStore } from "react-icons/fa6";
import { TbAffiliate } from "react-icons/tb";
import { SiUnity } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/* type Tlist = {
  id: number;
  name: string;
  icon: any;
  path: string;
  roles:string[];
  children?: {
    id: number;
    name: string;
    icon: any;
    path: string;
  }[];
}[];

const AllRouts: Tlist = [
  {
    id: 22,
    name: "Users",
    icon: <FaUsers />,
    path: "users",
    roles: ["super"], // Allowed roles
  },
  {
    id: 1,
    name: "Home",
    icon: <IoHomeOutline />,
    path: "home-page",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 2,
    name: "Faqs",
    icon: <FaQuestion />,
    path: "faqs",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 3,
    name: "Categories",
    icon: <TbCategory />,
    path: "cetegory",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 4,
    name: "Payment",
    icon: <MdPayment />,
    path: "payment-method",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 5,
    name: "Subscription",
    icon: <MdUnsubscribe />,
    path: "subscription",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 6,
    name: "faq unity",
    icon: <FaQuestion />,
    path: "faq-unity",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 7,
    name: "Nontification",
    icon: <FaRegBell />,
    path: "nontification",
    roles: ["super"], // Allowed roles
  },
  {
    id: 8,
    name: "Blogs",
    icon: <LiaBlogSolid />,
    path: "blogs",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 9,
    name: "coupons",
    icon: <RiCoupon3Line />,
    path: "Coupons",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 10,
    name: "Coloring actvities",
    icon: <MdOutlineFormatColorFill />,
    path: "coloring-actvities",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 11,
    name: "Wallet",
    icon: <FaWallet />,
    path: "wallet",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 12,
    name: "CategoriesProducts",
    icon: <HiBuildingStorefront />,
    path: "CategoriesProducts",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 13,
    name: "products",
    icon: <FaProductHunt />,
    path: "products",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 14,
    name: "wallet Requests",
    icon: <MdOutlineProductionQuantityLimits />,
    path: "walletProduct",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 15,
    name: "Orders",
    icon: <MdOutlineNote />,
    path: "orders",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 16,
    name: "DeliveryFees",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "deliveryFees",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 17,
    name: "Songs",
    icon: <GiLoveSong />,
    path: "songs",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 18,
    name: "Parents guides",
    icon: <FaTheRedYeti />,
    path: "parents-guides",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 19,
    name: "Affilates",
    icon: <FaUsers />,
    path: "affilates",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 20,
    name: "with Draw Affilates",
    icon: <FaUsers />,
    path: "withDrawAffilates",
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 21,
    name: "Affilates promotion",
    icon: <FaUsers />,
    path: "promotionAffilates",
    roles: ["super", "admin"], // Allowed roles
  },
]; */



const routes =[
  {
    id:1,
    rank:1,
    name:"Home",
    icon:<IoHomeOutline />,
    roles:["super","admin"],
    path:"home-page",
    isDrawwer:false,
  },
  {
    id:2,
    rank:7,
    name:"Report",
    isDrawwer:true,
    icon:<TbReportAnalytics />,
    children:[
      {
        id: 22,
        name: "Users",
        icon: <FaUsers />,
        path: "users",
        isDrawwer:false,
        roles: ["super"], // Allowed roles
      }]
  },
  {
    id:3,
    name:"General Settings",
    rank:2,
    isDrawwer:true,
    icon:<IoSettings />,
    children:[
      {
        id: 2,
        name: "Faqs",
        icon: <FaQuestion />,
        path: "faqs",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
      {
        id: 8,
        name: "Blogs",
        icon: <LiaBlogSolid />,
        path: "blogs",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
      {
        id: 4,
        name: "Payment",
        icon: <MdPayment />,
        path: "payment-method",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
      {
        id: 16,
        name: "DeliveryFees",
        icon: <MyCalendarTodayOutlinedIcon />,
        path: "deliveryFees",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },

    ]
  },
  {
    id:4,
    rank: 3,
    name:"Indvidual Account",
    icon:<MyBsPerson />,
    isDrawwer:true,
    children:[
      {
        id: 3,
        name: "Categories",
        icon: <TbCategory />,
        path: "cetegory",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
      {
        id: 5,
        name: "Subscription",
        icon: <MdUnsubscribe />,
        path: "subscription",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },{
        id: 10,
        name: "Coloring actvities",
        icon: <MdOutlineFormatColorFill />,
        path: "coloring-actvities",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },{
        id: 17,
        name: "Songs",
        icon: <GiLoveSong />,
        path: "songs",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },{
        id: 18,
        name: "Parents guides",
        icon: <FaTheRedYeti />,
        path: "parents-guides",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
    ]
  },
  {
    id:5,
    name:"Unity",
    rank:6,
    icon:<SiUnity />,
    isDrawwer:true,
    children:[
      {
        id: 6,
        name: "faq unity",
        icon: <FaQuestion />,
        path: "faq-unity",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
    ]
  },
  {
    id:6,
    name:"Affilliate",
    rank:5,
    icon:<TbAffiliate />,
    isDrawwer:true,
    children:[
      {
        id: 19,
        name: "Affilates",
        icon: <FaUsers />,
        path: "affilates",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },  {
        id: 20,
        name: "with Draw Affilates",
        icon: <FaUsers />,
        path: "withDrawAffilates",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      }, {
        id: 21,
        name: "Affilates promotion",
        icon: <FaUsers />,
        path: "promotionAffilates",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
    ]
  },
  {
    id:7,
    name:"Store",
    rank:4,
    icon:<FaStore />,
    isDrawwer:true,
    children:[
      {
        id: 12,
        name: "CategoriesProducts",
        icon: <HiBuildingStorefront />,
        path: "CategoriesProducts",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },  {
        id: 13,
        name: "products",
        icon: <FaProductHunt />,
        path: "products",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },{
        id: 15,
        name: "Orders",
        icon: <MdOutlineNote />,
        path: "orders",
        isDrawwer:false,
        roles: ["super", "admin"], // Allowed roles
      },
    ]
  },
  {
    id:8,
    name: "Coupons",
    rank:9,
    icon: <RiCoupon3Line />,
    path: "Coupons",
    isDrawwer:false,
    roles: ["super", "admin"], // Allowed roles
  },
  {
    id: 9,
    name: "Nontification",
    rank:8,
    icon: <FaRegBell />,
    path: "nontification",
    isDrawwer:false,
    roles: ["super"], // Allowed roles
  }
];

type Route = {
  id: number;
  name: string;
  icon: any;
  roles?: string[]; // Optional since some routes might not have roles
  path?: string;
  isDrawwer: boolean;
  rank?:number;
  children?: Route[];
};

const filterRoutesByRole = (routes: Route[], userRole: string): Route[] => {
  return routes
    .filter((route) => {
      // Filter top-level routes based on roles
      return !route.roles || route.roles.includes(userRole);
    })
    .map((route) => ({
      ...route,
      // Recursively filter child routes
      children: route.children
        ? filterRoutesByRole(route.children, userRole)
        : undefined,
    }));
};





const NavBar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.auth);
  const userRole = data?.user?.roles[0].name;
  const [open, setOpen] = React.useState(true);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(true);



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <NavBarStyle open={open}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MyMenuIcon />
        </IconButton>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className={open ? "header-open" : "header-close"}>
            {open ? (
              <>
                <img src=""></img>
              </>
            ) : (
              <></>
            )}
            <IconButton onClick={handleDrawerClose}>
              {open ? (
                <>
                  <MyChevronRightIcon />
                </>
              ) : (
                <>
                  <MyMenuIcon></MyMenuIcon>
                </>
              )}
            </IconButton>
          </DrawerHeader>
          <div className="nav">
            <div>

            <List style={{ display: "flex" , flexDirection:"column" , justifyContent:"space-between" , height:"100%"}}>
              {filterRoutesByRole(routes, userRole).map((route) => (
                <div key={route.id} style={{ marginBottom: "10px" ,order: route.rank}}>
                  {route.isDrawwer ? (
                    <Dropdown route={route} open={open} userRole={userRole}/>
                  ) : (
                    <SingleItem route={route} open={open} userRole={userRole}/>
                  )}
                </div>
              ))}
            </List>
            </div>
            <div>
              <List>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img className="profile-img" src={data?.image} />
                    </ListItemIcon>
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary={data?.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => dispatch(logOut())}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ExitToApp />
                    </ListItemIcon>
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary="Logout"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#FAFCFE",
            minHeight: "100vh",
            padding: "1rem 2rem",
            overflow: "auto",
          }}
          // style={{ overflowX: "hidden" }}
        >
          {/* <DrawerHeader /> */}
          <Suspense fallback={<h1>Loading....</h1>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </NavBarStyle>
  );
};

export default NavBar;
